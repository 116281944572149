import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["additionalInfo", "submitButton"];

  connect() {
    this.submitButtonTarget.disabled = true;
    this.setupEventListeners();
  }

  setupEventListeners() {
    this.element.addEventListener("submit", (event) => {
      event.preventDefault();
      const selectedRadio = this.element.querySelector(
        'input[type="radio"]:checked'
      );

      if (!this.isValidSubmission(selectedRadio)) {
        return;
      }

      event.target.submit();
    });

    this.element.querySelectorAll('input[type="radio"]').forEach((radio) => {
      radio.addEventListener("change", (event) => {
        this.submitButtonTarget.disabled = false;
        this.toggleAdditionalInfo(event.target);
      });
    });
  }

  toggleAdditionalInfo(radio) {
    this.additionalInfoTarget.classList.toggle(
      "hidden",
      !radio.hasAttribute("data-has-additional-info")
    );
  }

  isValidSubmission(selectedRadio) {
    if (!selectedRadio) {
      alert("Please select a reason");
      return false;
    }

    if (
      selectedRadio.hasAttribute("data-has-additional-info") &&
      !this.additionalInfoTarget.value.trim()
    ) {
      alert("Please provide additional information");
      return false;
    }

    return true;
  }
}
