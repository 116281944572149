import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["options", "message", "notRelevantButton", "name", "continueButton", "submitButton"];

  connect() {
    const activeOption = this.optionsTarget.querySelector(".js-active");

    if (activeOption) {
      this.continueButtonTarget.disabled = false;
    }

    this.optionsTarget.addEventListener("radio:change", (e) => {
      this.continueButtonTarget.disabled = false;
    });
  }

  continue(e) {
    e.preventDefault();

    leave(this.optionsTarget).then(() => {
      enter(this.messageTarget);
    });

    const activeOption = this.optionsTarget.querySelector(".js-active");

    this.nameTargets.forEach(nameTarget => {
      nameTarget.textContent = activeOption.querySelector(".js-name").textContent;
    });

    this.notRelevantButtonTarget.classList.add("hidden");
  }

  cancel(e) {
    e.preventDefault();

    leave(this.messageTarget).then(() => {
      enter(this.optionsTarget);
    });

    this.notRelevantButtonTarget.classList.remove("hidden");
  }

  blurbChange(e) {
    if (e.target.value.length > 0) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
