import { Controller } from "@hotwired/stimulus";
import { enter, leave } from "el-transition";

export default class extends Controller {
  static targets = ["container", "overlay", "slideover"];

  slideoverTargetConnected() {
    const cards = document.querySelectorAll(".js-highlight-card");

    cards.forEach((card) => {
      card.classList.remove("z-50");
    });

    this.idToHighlight = this.slideoverTarget.dataset.highlightId;
    this.shrinkContainer = JSON.parse(this.slideoverTarget.dataset.shrinkContainer);

    if (this.idToHighlight) {
      this.highlightTarget = document.getElementById(this.idToHighlight);
      this.highlightTarget.classList.add("z-50");
    }

    this.open();

    document.addEventListener("turbo:before-stream-render", (e) => {
      if (this.idToHighlight) {
        this.highlightTarget =
          e.detail.newStream.templateElement.content.getElementById(
            this.idToHighlight
          );

        if (this.highlightTarget) {
          this.highlightTarget.classList.add("z-50");
        }
      }
    });
  }

  open() {
    if (this.shrinkContainer) {
      this.containerTarget.classList.remove("w-full");
      this.containerTarget.classList.add("w-1/2");
    }

    this.containerTarget.appendChild(this.overlayTarget);

    enter(this.overlayTarget);
    enter(this.slideoverTarget);
  }

  close(e) {
    e.preventDefault();

    if (this.shrinkContainer) {
      this.containerTarget.classList.remove("w-1/2");
      this.containerTarget.classList.add("w-full");
    }

    leave(this.overlayTarget);
    leave(this.slideoverTarget);

    if (this.highlightTarget) {
      this.highlightTarget.classList.remove("z-50");
    }
  }
}
