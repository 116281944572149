import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["item"];

  connect() {
    this.ACTIVE_CLASS = ["border-primary-400", "js-active"];
    this.INACTIVE_CLASS = ["border-neutral-100", "opacity-70"];

    this.itemTargets.forEach((item) => {
      let radio = item.querySelector("input[type='radio']");

      radio.addEventListener("change", (evt) => {
        this._pick(item);
      });

      if (radio.checked) {
        this._pick(item);
      }

      if (item.dataset.comingSoon && JSON.parse(item.dataset.comingSoon)) {
        tippy(item, {
          content: "Coming soon!",
          placement: "bottom",
        });
      }
    });
  }

  disconnect() {
    this.itemTargets.forEach((item) => {
      let radio = item.querySelector("input[type='radio']");
      radio.removeEventListener("change", (evt) => {
        this._pick(item);
      });
    });
  }

  _pick(item) {
    // reset all
    this.itemTargets.forEach((item) => {
      item.classList.remove(...this.ACTIVE_CLASS);
      item.classList.add(...this.INACTIVE_CLASS);
    });

    // select item
    item.classList.add(...this.ACTIVE_CLASS);
    item.classList.remove(...this.INACTIVE_CLASS);

    // emit event
    this.element.dispatchEvent(
      new CustomEvent("radio:change", { detail: item, bubbles: true })
    );
  }
}
