import { Controller } from "@hotwired/stimulus";
import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["input"];
  static values = { url: String, updateUrl: String, fieldName: String };

  connect() {
    this.initializeDropzone();
    this.hideFileInput();
    this.bindDropzoneEvents();
  }

  initializeDropzone() {
    this.dropZone = new Dropzone(this.element, {
      url:
        this.urlValue ||
        this.inputTarget.getAttribute("data-direct-upload-url"),
      headers: { "X-CSRF-Token": this.getMetaValue("csrf-token") },
      maxFiles: this.data.get("maxFiles") || 1,
      maxFilesize: this.data.get("maxFileSize") || 5,
      acceptedFiles: this.data.get("acceptedFiles") || ".csv",
      addRemoveLinks: true,
      autoQueue: false,
    });
  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindDropzoneEvents() {
    const events = [
      { name: "addedfile", handler: (file) => this.onFileAdded(file) },
      { name: "removedfile", handler: (file) => this.onFileRemoved(file) },
      {
        name: "error",
        handler: (file, errorMessage) => this.onFileError(file, errorMessage),
      },
      { name: "complete", handler: (file) => this.onFileComplete(file) },
    ];

    events.forEach(({ name, handler }) => {
      this.dropZone.on(name, (...args) => {
        setTimeout(() => handler(...args), 100);
      });
    });
  }

  onFileAdded(file) {
    if (file.accepted) {
      const uploadController = new DirectUploadController(this, file);
      file.controller = uploadController;
      uploadController.start();
    }
  }

  onFileRemoved(file) {
    if (file.controller) {
      this.removeHiddenInput(file.controller.hiddenInput);
    }
  }

  onFileError(file, errorMessage) {
    console.error(`Upload error for file: ${file.name}`, errorMessage);
  }

  onFileComplete(file) {
    if (file.controller) {
      file.controller = null;
    }
    const progressElement = file.previewElement.querySelector(".dz-progress");
    if (progressElement) {
      progressElement.style.display = "none";
    }
  }

  updateServer(formData) {
    if (!this.updateUrlValue) return;

    fetch(this.updateUrlValue, {
      method: "PATCH",
      body: formData,
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "X-CSRF-Token": this.getMetaValue("csrf-token"),
      },
      credentials: "same-origin",
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);
        return response.text();
      })
      .then((html) => {
        if (html) {
          Turbo.renderStreamMessage(html);
        }
      })
      .catch((error) => {
        console.error("Update server error:", error);
        alert(
          "Failed to update file on the server. Please validate the file and try again."
        );
      });
  }

  removeHiddenInput(input) {
    if (input && input.parentNode) {
      input.parentNode.removeChild(input);
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element ? element.content : "";
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.source = source;
    this.file = file;
    this.directUpload = new DirectUpload(
      file,
      source.urlValue ||
        source.inputTarget.getAttribute("data-direct-upload-url"),
      this
    );
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, blob) => {
      if (error) {
        this.removeHiddenInput();
        this.source.dropZone.emit("error", this.file, error);
      } else {
        this.hiddenInput.value = blob.signed_id;
        this.updateServer(blob.signed_id);
        this.source.dropZone.emit("success", this.file);
      }
      this.source.dropZone.emit("complete", this.file);
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    this.source.inputTarget.insertAdjacentElement("afterend", input);
    return input;
  }

  updateServer(signedId) {
    const formData = new FormData();
    formData.append(this.source.fieldNameValue, signedId);
    this.source.updateServer(formData);
  }

  removeHiddenInput() {
    if (this.hiddenInput && this.hiddenInput.parentNode) {
      this.hiddenInput.parentNode.removeChild(this.hiddenInput);
    }
  }

  directUploadWillStoreFileWithXHR(xhr) {
    xhr.upload.addEventListener("progress", (event) => {
      const progress = (event.loaded / event.total) * 100;
      const progressElement =
        this.file.previewTemplate.querySelector(".dz-upload");
      if (progressElement) {
        progressElement.style.width = `${progress}%`;
      }
    });
  }
}
