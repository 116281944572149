import React from "react";
import { AmpersandProvider, InstallIntegration } from '@amp-labs/react';

export default function AmpersandIntegration({ integrationName, ampersandProject, ampersandApiKey, userId, userFullName, workspaceId, workspaceName }) {
  return (
    <AmpersandProvider options={{
      project: ampersandProject,
      apiKey: ampersandApiKey
    }}>
      <InstallIntegration
        integration = {integrationName}
        consumerRef = {userId}
        consumerName = {userFullName}
        groupRef = {workspaceId}
        groupName = {workspaceName}
      />
    </AmpersandProvider>
  )
}
